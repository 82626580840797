<div id="oiv-console" class="mobi-ui-margin-b-md" [ngStyle]="consoleStyleObject()">
    <div class="console-wrapper">
        <mobi-ui-textarea-major
            class="oiv-scrollbar-none"
            [autosize]="autosize()"
            [autosizeMaxRows]="5"
            [autosizeMinRows]="1"
            [rows]="1"
            [placeholder]="
                isFollowUpQuestion()
                    ? ('oiv.aiSearch.console.followUpQuestion' | translate)
                    : ('oiv.aiSearch.console.firstQuestion' | translate)
            "
            (focused)="focus()"
            [disabled]="!isIndexAvailable()"
        />
        @if (isGenerating()) {
            <mobi-ui-loading-indicator-major />
            <button
                (click)="stopGenerating()"
                class="is-squared mobi-ui-btn-icon-major mobi-ui-btn-icon-minor mobi-ui-margin-l-sm"
            >
                <mobi-ui-icon-major class="mobi-ui-margin-none" name="the_x" />
            </button>
        } @else if (haveMessage()) {
            <button
                [title]="'oiv.aiSearch.console.sendMessage' | translate"
                (click)="sendMessage()"
                class="is-squared mobi-ui-btn-icon-silent"
            >
                <mobi-ui-icon-major class="mobi-ui-margin-none" name="paper_plane" />
            </button>
        }
    </div>

    @if (isDisplayCreateNewConversationButton()) {
        <button
            type="button"
            (click)="createNewConversation()"
            class="createNewConversationBtn mobi-ui-btn-icon-minor"
            [title]="'oiv.aiSearch.console.createNewConversation' | translate"
        >
            <mobi-ui-icon-major class="mobi-ui-margin-none" name="item_new" />
        </button>
    }
</div>
