import { ChangeDetectionStrategy, Component, effect, inject } from '@angular/core';

import { Title } from '@angular/platform-browser';

import { toSignal } from '@angular/core/rxjs-interop';

import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

import { MOBILIAR, OivCoreSharedFacade } from '@mobi/oiv-viewer-utils-ng-jslib';

@Component({
    standalone: true,
    selector: 'oiv-viewer-not-available',
    templateUrl: './not-available.component.html',
    styleUrl: './not-available.component.scss',
    imports: [TranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotAvailableComponent {
    #title = inject(Title);
    #oivCoreSharedFacade = inject(OivCoreSharedFacade);

    #appTitle = toSignal(this.#oivCoreSharedFacade.translatedMessage('oiv.app.title'), {
        initialValue: 'Allgemeine Bedingungen',
    });
    #appMobiliar = toSignal(this.#oivCoreSharedFacade.translatedMessage('oiv.app.mobiliar'), {
        initialValue: 'die Mobiliar',
    });
    #appProtekta = toSignal(this.#oivCoreSharedFacade.translatedMessage('oiv.app.protekta'), {
        initialValue: 'Protekta',
    });

    constructor() {
        effect(() => {
            // Should set the browser title in here instead of the app component
            // It's because if you set the browser title in the app component, at that time,
            // this.#oivCoreSharedFacade.translations = { de: de, fr: fr, it: it } is not yet set
            // So, the translatedMessage will return the key instead of the translated value
            const appTitle =
                this.#oivCoreSharedFacade.appSource() === MOBILIAR ? this.#appMobiliar() : this.#appProtekta();
            this.#title.setTitle(`${this.#appTitle()} | ${appTitle}`);
        });
    }
}
