<div class="mobi-ui-wrapper-content">
    <div class="mobi-ui-container-overflow">
        <div class="mobi-ui-container">
            <div class="mobi-ui-col-7/8 sm:mobi-ui-col-5/8 mobi-ui-margin-none">
                <mobi-ui-illustration-major name="basic_person_homeoffice" />
            </div>
            <div class="mobi-ui-grid mobi-ui-centered mobi-ui-padding-sm">
                <div class="mobi-ui-col-1/1 md:mobi-ui-col-6/8">
                    <h1 class="mobi-ui-margin-sm mobi-ui-font-size-600 sm:mobi-ui-font-size-900">
                        {{ 'oiv.not-found.heading.title' | translate }}
                    </h1>
                    <p class="mobi-ui-margin-sm mobi-ui-font-size-200 sm:mobi-ui-font-size-300">
                        {{ 'oiv.not-found.heading.text' | translate }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="mobi-ui-wrapper-content stacked-container-background">
    <div class="mobi-ui-container-overflow">
        <div class="mobi-ui-container">
            <div class="mobi-ui-grid mobi-ui-centered">
                <div class="mobi-ui-col-1/1 md:mobi-ui-col-6/8 xl:mobi-ui-col-5/8">
                    <div class="mobi-ui-ink-default mobi-ui-padding-t-xxl">
                        <div class="mobi-ui-grid">
                            <div class="mobi-ui-col-1/1 xs:mobi-ui-col-2/3 md:mobi-ui-col-1/2">
                                <oiv-viewer-stacked-container
                                    link="{{ links().notFoundPageOverview }}"
                                    title="{{ overviewContent() | translate }}"
                                >
                                    <mobi-ui-illustration-major
                                        name="basic_desktop_file_folder"
                                    ></mobi-ui-illustration-major>
                                </oiv-viewer-stacked-container>
                            </div>
                            <div class="mobi-ui-col-1/1 xs:mobi-ui-col-2/3 md:mobi-ui-col-1/2">
                                @if (isB2E()) {
                                    <oiv-viewer-stacked-container
                                        link="{{ links().notFoundPageOverviewRedNet }}"
                                        title="{{ 'oiv.not-found.avb-overview-rednet' | translate }}"
                                    >
                                        <mobi-ui-illustration-major
                                            name="basic_home_signin_laptop"
                                        ></mobi-ui-illustration-major>
                                    </oiv-viewer-stacked-container>
                                } @else {
                                    <oiv-viewer-more-information [links]="links()" />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
