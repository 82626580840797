<div
    (click)="handleBackToOverview()"
    class="oiv-back-to-overview mobi-ui-card-silent is-small is-gap is-row is-clickable mobi-ui-font-italic back-sticky"
>
    <mobi-ui-icon-major name="left" />
    <span>{{ 'oiv.aiSearch.backToOverview' | translate }}</span>
</div>

<!-- Disclaimer -->
<div class="mobi-ui-card-silent mobi-ui-ink-blue">
    <div class="mobi-ui-text-block-major">
        <mobi-ui-icon-major name="info"></mobi-ui-icon-major>
        <div class="mobi-ui-text-block-content-major">
            {{ 'oiv.aiSearch.disclaimer' | translate }}
        </div>
    </div>
</div>

<div class="mobi-ui-margin-t-xl">
    <div class="mobi-ui-margin-b-sm">
        <oiv-viewer-separator
            [dateTime]="displayDatetime(conversation().createdDate)"
            [text]="'oiv.aiSearch.newConversationSeparator' | translate"
            [isBold]="true"
        />

        <!--Filtering component-->
        @if (conversation().combinedId) {
            <div class="mobi-ui-card-silent">
                <div class="mobi-ui-text-block-major">
                    <mobi-ui-icon-major name="placeholder"></mobi-ui-icon-major>
                    <div class="mobi-ui-text-block-content-major">
                        <span data-cy="filterContent" class="mobi-ui-text-block-title-major">
                            {{ 'oiv.aiSearch.filter.chosenTopic' | translate }}</span
                        >
                        @if (conversation().chosenFilter === 'all-topics') {
                            {{ 'oiv.aiSearch.filter.selectAllTopics' | translate }}
                        } @else {
                            {{ conversation().chosenFilter }}
                        }
                    </div>
                </div>
            </div>
        } @else {
            <mobi-ui-dropdown-major
                data-cy="filterDropdown"
                [emptyOptionLabel]="'oiv.aiSearch.filter.selectTopic' | translate"
                (valueChange)="handleValueChange($event)"
                [class.outline]="isMissingFilter()"
                (click)="dropdownClick()"
            >
                <mobi-ui-dropdown-option-major value="all-topics">{{
                    'oiv.aiSearch.filter.selectAllTopics' | translate
                }}</mobi-ui-dropdown-option-major>
                @for (title of chapterTitles(); track title) {
                    <mobi-ui-dropdown-option-major value="{{ title }}">{{ title }}</mobi-ui-dropdown-option-major>
                }
            </mobi-ui-dropdown-major>
        }
    </div>

    @if (displayPrompts()) {
        <oiv-viewer-prompt (selectPrompt)="selectPrompt($event)" [prompts]="prompts()" />
    }

    @if (messages() && messages().length > 0) {
        @for (message of messages(); track message.id) {
            <div class="mobi-ui-margin-b-xl">
                @if (message.role === 'user') {
                    <!-- Displaying the timeline for each new Question -->
                    <div class="mobi-ui-padding-y-lg oiv-info-color">
                        <div class="mobi-ui-text-center">
                            @if ($index !== 0) {
                                <oiv-viewer-separator
                                    [dateTime]="displayDatetime(message.creationTimestamp)"
                                    [text]="'oiv.aiSearch.followUpQuestionSeparator' | translate"
                                    [isBold]="false"
                                />
                            }
                        </div>
                    </div>
                    <oiv-user-message [message]="message"></oiv-user-message>
                } @else {
                    <oiv-assistant-message
                        [message]="renderMessageReference(message)"
                        (feedbackSent)="handleSendFeedback($event)"
                        [categories]="feedbackCategories()"
                        [references]="handleReferences(message)"
                    ></oiv-assistant-message>
                }
            </div>
        }
    }

    @if (isFeedbackSent()) {
        <mobi-ui-messages-major>
            <mobi-ui-message-item-major
                [level]="getFeedbackData().level"
                [messageCloseTimeout]="MESSAGE_CLOSE_TIMEOUT"
                (messageClose)="handleMessageClose()"
            >
                {{ 'oiv.feedback.send.success' | translate }}
            </mobi-ui-message-item-major>
        </mobi-ui-messages-major>
    }

    @if (isMissingFilter()) {
        <div class="mobi-ui-margin-t-sm">
            <oiv-viewer-error-message
                [messageTitle]="'oiv.error.aiSearch.filterIsMissing.title' | translate"
                [messageDescription]="'oiv.error.aiSearch.filterIsMissing.description' | translate"
            />
        </div>
    }
</div>
