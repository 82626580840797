import { inject, Injectable, Signal } from '@angular/core';

import { v4 as uuidv4 } from 'uuid';

import { DateTime } from 'luxon';

import { DeepSignal } from '@ngrx/signals';

import { Conversation, Feedback, Message, MessageRole } from '../model/message.model';
import { AiStore } from '../store/ai.store';
import { PromptDTO } from '../../../../oiv-viewer/core/data/service/oiv-viewer.service';
import { AssetInfo } from '../../../../share/model/atomic-model';
import { MessageDTO, MessageRequestDTO } from '../service/question-and-answer.service';

@Injectable()
export class AiFacade {
    #aiStore = inject(AiStore);

    get previousConversations(): Signal<Conversation[]> {
        return this.#aiStore.previousConversations;
    }

    get activeConversation(): DeepSignal<{
        conversation: Conversation;
        isFollowUpQuestion: boolean;
        isDisplaySuggestion: boolean;
        isGenerating: boolean;
    }> {
        return this.#aiStore.activeConversation;
    }

    get isIndexAvailable(): Signal<boolean> {
        return this.#aiStore.isIndexAvailable;
    }

    get isPending(): Signal<boolean> {
        return this.#aiStore.isPending;
    }

    set currentSelectedFilterItem(filterItem: string) {
        this.#aiStore.setCurrentSelectedFilterItem(filterItem);
    }

    get prompts(): Signal<PromptDTO[]> {
        return this.#aiStore.prompts;
    }

    initializeAiStore(assetInfo: AssetInfo) {
        this.#aiStore.initialize(assetInfo);
    }

    sendMessageSubmissionRequested(
        activeConversation: Conversation,
        productArt: number,
        documentArt: number,
        messageText: string,
        assetType: string,
        contentVersion: string,
    ) {
        // Handle messages in the store
        this.#handleMessagesInStore(activeConversation, messageText);
        // Handle messages to send to AI server
        this.#sendMessagesToAiServer(activeConversation, productArt, documentArt, assetType, contentVersion);
    }

    sendFeedback(messageId: string, feedback: Feedback) {
        this.#aiStore.sendFeedback({ messageId, feedback: feedback });
    }

    stopGeneratingRequested() {
        this.#aiStore.stopGeneratingRequested();
    }

    loadPrompts() {
        this.#aiStore.loadPrompts();
    }

    addTmpConversation() {
        this.#aiStore.addTmpConversation();
    }

    addConversations(conversations: Conversation[]) {
        this.#aiStore.addConversations(conversations);
    }

    selectConversation(conversation: Conversation) {
        this.#aiStore.setActiveConversationId(conversation.id);
    }

    clearAll() {
        this.#aiStore.cleanUp();
    }

    #handleMessagesInStore(currentConversation: Conversation, messageText: string) {
        const message: Message = {
            id: uuidv4(),
            content: messageText,
            creationTimestamp: DateTime.now(),
            role: 'user',
            status: 'completed',
        };
        currentConversation.messages.push(message);
        this.#aiStore.updateConversation(currentConversation);
    }

    #sendMessagesToAiServer(
        activeConversation: Conversation,
        productArt: number,
        documentArt: number,
        assetType: string,
        contentVersion: string,
    ) {
        // get all existing messages in current conversation
        const existingMessages: MessageDTO[] = activeConversation.messages
            .map(message => ({
                content: message.content ?? '',
                role: message.role as MessageRole,
            }))
            .filter(message => message.content !== undefined);

        const request: MessageRequestDTO = {
            conversation_id: '',
            text_reference: this.#aiStore.textReference(),
            fdt_dokumentArt: documentArt.toString(),
            fdt_produktArt: productArt.toString(),
            language: this.#aiStore.language(),
            asset_type: assetType,
            version: contentVersion,
            messages: existingMessages,
        };
        // if filter is 'all-topics',
        // remove filter attribute because question answering service treat it as 'all-filters'
        if (activeConversation.chosenFilter !== 'all-topics') {
            request.filter = activeConversation.chosenFilter;
        }

        if (activeConversation.combinedId) {
            this.#aiStore.sendMessage({
                requestPayload: request,
                currentConversation: activeConversation,
                needToCreateConversation: false,
            });
        } else {
            this.#aiStore.sendMessage({
                requestPayload: request,
                currentConversation: activeConversation,
                needToCreateConversation: true,
            });
        }
    }
}
