import { ChangeDetectionStrategy, Component, computed, effect, inject } from '@angular/core';

import { CommonModule } from '@angular/common';

import { Title } from '@angular/platform-browser';

import { toSignal } from '@angular/core/rxjs-interop';

import {
    MobiUiIconComponent,
    MobiUiIllustrationComponent,
    MobiUiIllustrationsRegistry,
} from '@mobi/rwc-ui-components-ng-jslib';
import {
    mobiIllustrationBasicDesktopFileFolder,
    mobiIllustrationBasicHomeSigninLaptop,
    mobiIllustrationBasicPersonHomeoffice,
} from '@mobi/rwc-utils-illustrations-jslib';
import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

import { B2E, MOBILIAR, OivCoreSharedFacade } from '@mobi/oiv-viewer-utils-ng-jslib';

import { StackedContainerComponent } from '../../share/components/stacked-container/stacked-container.component';
import { MoreInformationComponent } from '../../share/components/more-information/more-information.component';

@Component({
    standalone: true,
    selector: 'oiv-viewer-not-found-page',
    templateUrl: './not-found-page.component.html',
    styleUrls: ['./not-found-page.component.scss'],
    imports: [
        MobiUiIllustrationComponent,
        TranslatePipe,
        MobiUiIconComponent,
        StackedContainerComponent,
        CommonModule,
        MoreInformationComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundPageComponent {
    #oivCoreSharedFacade = inject(OivCoreSharedFacade);
    #title = inject(Title);

    #appTitle = toSignal(this.#oivCoreSharedFacade.translatedMessage('oiv.app.title'), {
        initialValue: 'Allgemeine Bedingungen',
    });
    #appMobiliar = toSignal(this.#oivCoreSharedFacade.translatedMessage('oiv.app.mobiliar'), {
        initialValue: 'die Mobiliar',
    });
    #appProtekta = toSignal(this.#oivCoreSharedFacade.translatedMessage('oiv.app.protekta'), {
        initialValue: 'Protekta',
    });

    isB2E = computed(() => this.#oivCoreSharedFacade.appContext() === B2E);
    appSource = this.#oivCoreSharedFacade.appSource;
    links = this.#oivCoreSharedFacade.links;
    overviewContent = computed(() =>
        this.appSource() === MOBILIAR ? 'oiv.not-found.avb-overview' : 'oiv.not-found.protekta.avb-overview',
    );

    constructor() {
        inject(MobiUiIllustrationsRegistry).registerIllustrations([
            mobiIllustrationBasicPersonHomeoffice,
            mobiIllustrationBasicDesktopFileFolder,
            mobiIllustrationBasicHomeSigninLaptop,
        ]);
        effect(() => {
            // Should set the browser title in here instead of the app component
            // It's because if you set the browser title in the app component, at that time,
            // this.#oivCoreSharedFacade.translations = { de: de, fr: fr, it: it } is not yet set
            // So, the translatedMessage will return the key instead of the translated value
            const appTitle = this.appSource() === MOBILIAR ? this.#appMobiliar() : this.#appProtekta();
            this.#title.setTitle(`${this.#appTitle()} | ${appTitle}`);
        });
    }
}
