import { Component, computed, effect, inject, OnInit, signal, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTime } from 'luxon';

import { Router } from '@angular/router';

import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

import {
    MobiUiLoadingIndicatorComponent,
    MobiUiMessageItemComponent,
    MobiUiMessagesComponent,
} from '@mobi/rwc-ui-components-ng-jslib';

import { OivCoreSharedFacade } from '@mobi/oiv-viewer-utils-ng-jslib';

import { OIV_TYPE } from '@mobi/oiv-viewer-xml-parser-ng-jslib';

import { AssetInfo } from '../../../../share/model/atomic-model';
import { SeparatorComponent } from '../../ui/separator/separator.component';
import { AssistantMessageComponent } from '../../ui/assistant-message/assistant-message.component';
import { UserMessageComponent } from '../../ui/user-message/user-message.component';
import { AiFacade } from '../../data/facade/ai-facade';

import { Conversation } from '../../data/model/message.model';

import { Helper } from '../../../../share/helper';

import { PromptComponent } from '../../ui/prompt/prompt.component';

import { ErrorMessageComponent } from '../../ui/error-message/error-message.component';
import { OivViewerFacade } from '../../../../oiv-viewer/core/data/facade/oiv-viewer-facade';
import { ConversationOverviewComponent } from '../../ui/conversation-overview/conversation-overview.component';
import { ConversationDetailComponent } from '../../ui/conversation-detail/conversation-detail.component';
import { ConsoleComponent } from '../../ui/console/console.component';
type ScrollToPosition = 'top' | 'bottom';
@Component({
    selector: 'oiv-viewer-conversation',
    standalone: true,
    imports: [
        CommonModule,
        SeparatorComponent,
        TranslatePipe,
        AssistantMessageComponent,
        UserMessageComponent,
        PromptComponent,
        MobiUiMessageItemComponent,
        MobiUiMessagesComponent,
        ErrorMessageComponent,
        ConversationOverviewComponent,
        ConversationDetailComponent,
        MobiUiLoadingIndicatorComponent,
        ConsoleComponent,
    ],
    templateUrl: './conversation.component.html',
    styleUrl: './conversation.component.scss',
})
export class ConversationComponent implements OnInit {
    #helper = inject(Helper);
    #router = inject(Router);
    #oivViewerFacade = inject(OivViewerFacade);
    #coreSharedFacade = inject(OivCoreSharedFacade);
    #aiFacade = inject(AiFacade);

    consoleComponent = viewChild.required(ConsoleComponent);

    chapters = this.#oivViewerFacade.avbChapters;
    feedbackCategories = this.#oivViewerFacade.feedbackCategories;
    prompts = this.#aiFacade.prompts;
    isIndexAvailable = this.#aiFacade.isIndexAvailable;
    isPending = this.#aiFacade.isPending;
    previousConversations = this.#aiFacade.previousConversations;
    activeConversation = this.#aiFacade.activeConversation;
    language = this.#coreSharedFacade.currentLanguage;

    isMissingFilter = signal(false);
    hideOverview = signal(false);
    baseUrl = computed(() =>
        this.#helper.getBaseUrl(
            this.#router,
            this.#coreSharedFacade.appContext(),
            this.#coreSharedFacade.isApprovalMode(),
        ),
    );
    conversationHasMessage = computed(
        () =>
            this.activeConversation.conversation().messages &&
            this.activeConversation.conversation().messages.length > 0,
    );
    rootChapter = computed(() => ({
        eId: 'avb',
        type: OIV_TYPE.CHAPTER_TITLE,
        content: this.chapters(),
    }));
    chapterTitles = computed(() => this.#helper.getAllChapterTitles(this.chapters()));

    #avbMetaData = this.#oivViewerFacade.avbMetadata;
    #currentLanguage = this.#coreSharedFacade.currentLanguage();

    constructor() {
        this.#onConservationsEffect();
        this.#onIndexAvailableEffect();

        // TODO: This is a workaround to fix the issue of the language not being set when the component is initialized
        effect(
            () => {
                if (this.language() && this.#avbMetaData() && this.#avbMetaData().language !== this.#currentLanguage) {
                    const assetInfo = {
                        language: this.#avbMetaData().language,
                        textReference: this.#avbMetaData().textReference,
                        assetType: this.#avbMetaData().assetType,
                        contentVersion: this.#avbMetaData().contentVersion,
                    } as AssetInfo;
                    this.#aiFacade.initializeAiStore(assetInfo);
                    this.#currentLanguage = this.language();
                    // refresh invalid filter
                    this.isMissingFilter.set(false);
                }
            },
            { allowSignalWrites: true },
        );
    }

    ngOnInit(): void {
        const assetInfo = {
            language: this.#avbMetaData().language,
            textReference: this.#avbMetaData().textReference,
            assetType: this.#avbMetaData().assetType,
            contentVersion: this.#avbMetaData().contentVersion,
        } as AssetInfo;
        this.#aiFacade.initializeAiStore(assetInfo);
        this.#handleConversationsInLocalStorage(assetInfo);
    }

    #handleConversationsInLocalStorage(assetInfo: AssetInfo) {
        const key = `${assetInfo.textReference}-${assetInfo.language}-${assetInfo.assetType}-conversations`;
        // Update the conversation list from the local storage
        const contentVersionStore = localStorage.getItem('contentVersion');
        const conversationsStore = localStorage.getItem(key);
        if (conversationsStore) {
            const conversations = JSON.parse(conversationsStore) as any[];
            // Clear the cache local storage if the conversation belongs to the old AVB version
            if (contentVersionStore && Number(contentVersionStore) < Number(assetInfo.contentVersion)) {
                localStorage.removeItem(key);
                // Update the latest version of AVB
                localStorage.setItem('contentVersion', assetInfo.contentVersion);
            } else {
                // Remove all conversations that older than 2 months
                const filteredConversations = [
                    ...conversations
                        .map(item => {
                            return {
                                ...item,
                                createdDate: DateTime.fromISO(item.createdDate),
                                modifiedDate: DateTime.fromISO(item.modifiedDate),
                            } as Conversation;
                        })
                        .filter(item => this.#isConversationNewerThan2Months(item.modifiedDate)),
                ];
                // Reset localStorage to keep only newer conversations
                localStorage.setItem(key, JSON.stringify(filteredConversations));
                // Set to the store
                this.#aiFacade.addConversations(filteredConversations);
            }
        }
    }

    #isConversationNewerThan2Months(modifiedDate: DateTime) {
        const now = DateTime.now();
        const twoMonthsAgo = now.minus({ months: 2 });

        // False: modified over 2 months
        return modifiedDate > twoMonthsAgo;
    }

    handleFilterChange(filterItem: string) {
        this.#aiFacade.currentSelectedFilterItem = filterItem;
    }

    handleFilterOpen(isOpened: boolean) {
        // we auto scroll to bottom when the filter is opened
        // and on the second conversation
        if (isOpened && this.activeConversation()) {
            this.#scrollToBottom();
        }
    }

    selectPrompt(suggestion: string) {
        this.consoleComponent().textarea().writeValue(suggestion);
        this.consoleComponent().sendMessage();
    }

    handleSendFeedback({ messageId, feedbackData }: any) {
        this.#aiFacade.sendFeedback(messageId, feedbackData);
    }

    handleBackToOverview() {
        this.hideOverview.set(false);
        this.isMissingFilter.set(false);
        this.consoleComponent().textarea().writeValue('');
        this.stopGenerating();
    }

    #onIndexAvailableEffect() {
        effect(
            () => {
                if (this.isIndexAvailable()) {
                    // load prompts
                    this.#aiFacade.loadPrompts();
                } else {
                    this.#aiFacade.clearAll();
                }
            },
            { allowSignalWrites: true },
        );
    }

    #onConservationsEffect() {
        effect(() => {
            if (this.activeConversation.conversation().messages.length > 0) {
                this.scrollTo('bottom');
            }
        });
    }
    #scrollToBottom() {
        let div = document.querySelector('.overlay');
        if (!this.#coreSharedFacade.isMobile()) {
            div = document.querySelector('.oiv-sticky');
        }
        if (!div) {
            return;
        }
        div.scrollTo({ top: div.scrollHeight + 150, behavior: 'smooth' });
    }

    scrollTo(position: ScrollToPosition): void {
        if (position === 'top') {
            document.defaultView?.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } else {
            setTimeout(() => {
                this.#scrollToBottom();
            }, 100);
        }
    }

    send(submitMessage: string) {
        if (this.activeConversation().conversation.chosenFilter === '' || submitMessage === '') {
            this.isMissingFilter.set(true);
            this.scrollTo('bottom');
            return;
        }
        this.isMissingFilter.set(false);
        this.#aiFacade.sendMessageSubmissionRequested(
            this.activeConversation.conversation(),
            this.#avbMetaData().productArt || 0,
            this.#avbMetaData().documentArt || 0,
            submitMessage,
            this.#avbMetaData().assetType || '',
            this.#avbMetaData().contentVersion || '',
        );
    }

    stopGenerating() {
        this.#aiFacade.stopGeneratingRequested();
    }

    createNewConversation() {
        this.hideOverview.set(true);
        this.#aiFacade.addTmpConversation();
    }

    selectConversation(conversation: Conversation) {
        this.hideOverview.set(true);
        this.#aiFacade.selectConversation(conversation);
    }

    protected readonly DateTime = DateTime;
}
