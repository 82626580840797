import {
    ChangeDetectionStrategy,
    Component,
    DoCheck,
    effect,
    HostListener,
    inject,
    OnInit,
    Signal,
    signal,
} from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { toSignal } from '@angular/core/rxjs-interop';

import { Title } from '@angular/platform-browser';

import { MobiUiIconsRegistry } from '@mobi/rwc-ui-components-ng-jslib';
import { UserSessionService } from '@mobi/rwc-utils-ng-jslib';

import {
    mobiIconArrowUpperRight,
    mobiIconDownload,
    mobiIconMenu,
    mobiIconMessage,
    mobiIconSearchText,
    mobiIconSignpost,
    mobiIconTheX,
    mobiIconUp,
} from '@mobi/rwc-utils-icons-jslib';

import {
    B2E,
    MOBILIAR,
    OivCoreSharedFacade,
    FEATURE_TOGGLE,
    TealiumTrackingFacade,
} from '@mobi/oiv-viewer-utils-ng-jslib';

import { AI_SEARCH, INDEX_SEARCH, NAVIGATION, PRE_PRODUCT_URL, PRODUCT_URL } from '../../../util/constant/common';
import { CoverSheet } from '../../navigation/model/cover-sheet';
import { isHTMLElementInViewport } from '../../../util/dom';
import { OivViewerFacade } from '../data/facade/oiv-viewer-facade';

@Component({
    selector: 'oiv-viewer-main-layout',
    templateUrl: './main-layout.component.html',
    styleUrl: './main-layout.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayoutComponent implements OnInit, DoCheck {
    #oivViewerFacade = inject(OivViewerFacade);
    #coreSharedFacade = inject(OivCoreSharedFacade);
    #tealiumTrackingFacade = inject(TealiumTrackingFacade);
    #userSessionService = inject(UserSessionService);
    #route = inject(ActivatedRoute);
    #title = inject(Title);

    protected readonly INDEX_SEARCH = INDEX_SEARCH;
    protected readonly AI_SEARCH = AI_SEARCH;
    protected readonly NAVIGATION = NAVIGATION;
    readonly WAITING_TIME = 7000;

    appSource = this.#coreSharedFacade.appSource;
    avbChapters = this.#oivViewerFacade.avbChapters;
    appContext = this.#coreSharedFacade.appContext;
    coverSheet: Signal<CoverSheet> = this.#oivViewerFacade.coverSheet;
    isMobile = this.#coreSharedFacade.isMobile;
    isQAOn = this.#oivViewerFacade.getTheFeature(FEATURE_TOGGLE.QnA);

    isOpenOverlay = this.#coreSharedFacade.isOverlayOpen;
    majorValue = signal<string>('');
    showScrollButton = signal(false);

    sideBarStyleObject: any;
    scrollingUp = false;
    lastScrollPosition = 0;

    #timeoutId: any;

    #appTitle = toSignal(this.#coreSharedFacade.translatedMessage('oiv.app.title'), {
        initialValue: 'Allgemeine Bedingungen',
    });
    #appMobiliar = toSignal(this.#coreSharedFacade.translatedMessage('oiv.app.mobiliar'), {
        initialValue: 'die Mobiliar',
    });
    #appProtekta = toSignal(this.#coreSharedFacade.translatedMessage('oiv.app.protekta'), {
        initialValue: 'Protekta',
    });
    #translatedSwitchMenuTitle = toSignal(this.#coreSharedFacade.translatedMessage('oiv.navigation.splitButton.menu'), {
        initialValue: 'Inhalt',
    });
    #translatedSwitchSearchTitle = toSignal(
        this.#coreSharedFacade.translatedMessage('oiv.navigation.splitButton.search'),
        { initialValue: 'Suche' },
    );
    #translatedSwitchQATitle = toSignal(this.#coreSharedFacade.translatedMessage('oiv.navigation.splitButton.qa'), {
        initialValue: 'Q&A',
    });

    constructor() {
        inject(MobiUiIconsRegistry).registerIcons([
            mobiIconSignpost,
            mobiIconArrowUpperRight,
            mobiIconDownload,
            mobiIconTheX,
            mobiIconSearchText,
            mobiIconMenu,
            mobiIconMessage,
            mobiIconUp,
        ]);
        effect(() => {
            if (this.coverSheet().title && this.coverSheet().info) {
                this.#updateAppTitle();
            }
        });
    }

    ngOnInit() {
        this.#coreSharedFacade.approvalMode = this.#route.snapshot.data['approvalMode'] || false;
        this.#oivViewerFacade.load(this.#route.snapshot.params['urlName'] || '');
        if (this.appContext() === B2E) {
            this.#oivViewerFacade.loadFeedbackCategories();
        }
        this.#setupTealiumTracking();
        this.checkScrollPosition();
    }

    ngDoCheck(): void {
        this.updateStickySidebarWidth();
        this.addSidebarPaddingToPreventFooterOverlap();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.updateStickySidebarWidth();
        this.addSidebarPaddingToPreventFooterOverlap();
    }

    @HostListener('window:scroll', [])
    checkScrollPosition() {
        const scrollPosition = document.documentElement.scrollTop;
        // Check if User scrolls up
        this.scrollingUp = scrollPosition < this.lastScrollPosition;

        this.lastScrollPosition = scrollPosition;

        if (scrollPosition > window.innerHeight && this.scrollingUp) {
            this.showScrollButton.set(true);
            // Wait 7 seconds before hiding the button
            clearTimeout(this.#timeoutId); // Kill the previous timeout section every time user's scroll
            this.#timeoutId = setTimeout(() => {
                this.showScrollButton.set(false);
            }, this.WAITING_TIME);
        } else {
            this.showScrollButton.set(false);
        }
    }

    updateStickySidebarWidth() {
        this.sideBarStyleObject = {
            ...this.sideBarStyleObject,
            ...{ width: document.getElementById('sticky-sidebar-wrapper')?.offsetWidth + 'px' },
        };
    }

    addSidebarPaddingToPreventFooterOverlap() {
        let paddingFooter;
        if (this.majorValue() === AI_SEARCH) {
            paddingFooter = isHTMLElementInViewport(document.getElementById('oiv-footer-wrapper')) ? '150px' : '50px';
        } else {
            paddingFooter = isHTMLElementInViewport(document.getElementById('oiv-footer-wrapper')) ? '100px' : '0px';
        }
        this.sideBarStyleObject = { ...this.sideBarStyleObject, ...{ paddingBottom: paddingFooter } };
    }

    handleMajorValueChange(value: any) {
        this.majorValue.set(value as string);

        // Track the switcher event
        this.#trackSwitcherEvent(value);
    }

    scrollToTop() {
        (function smoothScroll() {
            const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothScroll);
                window.scrollTo(0, currentScroll - currentScroll / 8);
            }
        })();
    }

    #setupTealiumTracking() {
        const domainUrl =
            (window.document.defaultView as Window).MOBI_RWC_CONFIG?.stage === 'prod' ? PRODUCT_URL : PRE_PRODUCT_URL;
        this.#tealiumTrackingFacade.addVolatile('user_loginStatus', this.appContext() === B2E ? 'true' : 'false');
        this.#tealiumTrackingFacade.addVolatile('rwc_user_session_id', this.#userSessionService.getId());
        this.#tealiumTrackingFacade.addVolatile('german_domain_url', domainUrl);
    }

    #trackSwitcherEvent(value: string) {
        const switcherValue = () => {
            switch (value) {
                case NAVIGATION:
                    return this.#translatedSwitchMenuTitle();
                case INDEX_SEARCH:
                    return this.#translatedSwitchSearchTitle();
                case AI_SEARCH:
                    return this.#translatedSwitchQATitle();
                default:
                    return '';
            }
        };
        this.#tealiumTrackingFacade.trackInteractionEvent('search-qa', 'switcher', switcherValue(), 'switch');
    }

    #updateAppTitle() {
        const edition = this.coverSheet().info.split(' ');
        const editionDate = edition.slice(1).join(' '); // Remove the first word from the edition
        const avbTitleAndEdition = `${this.coverSheet().title} ${editionDate}`;
        const appTitle = this.#coreSharedFacade.appSource() === MOBILIAR ? this.#appMobiliar() : this.#appProtekta();
        this.#title.setTitle(`${this.#appTitle()} ${avbTitleAndEdition} | ${appTitle}`);
    }
}
