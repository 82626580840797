import { Component, inject, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MobiUiIconComponent, MobiUiIconsRegistry } from '@mobi/rwc-ui-components-ng-jslib';
import { mobiIconItemNew, mobiIconMessageConversation, mobiIconRight } from '@mobi/rwc-utils-icons-jslib';
import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

import { LIST_LANGUAGE, OivCoreSharedFacade } from '@mobi/oiv-viewer-utils-ng-jslib';

import { Conversation } from '../../data/model/message.model';

@Component({
    selector: 'oiv-viewer-conversation-overview',
    standalone: true,
    imports: [CommonModule, MobiUiIconComponent, TranslatePipe],
    templateUrl: './conversation-overview.component.html',
    styleUrl: './conversation-overview.component.scss',
})
export class ConversationOverviewComponent {
    #coreSharedFacade = inject(OivCoreSharedFacade);

    conversations = input<Conversation[]>();
    language = this.#coreSharedFacade.currentLanguage;

    selectedConversation = output<Conversation>();
    newConversation = output<void>();

    constructor() {
        inject(MobiUiIconsRegistry).registerIcons([mobiIconMessageConversation, mobiIconItemNew, mobiIconRight]);
    }

    createNewConversation() {
        this.newConversation.emit();
    }

    selectConversation(conversation: Conversation) {
        this.selectedConversation.emit(conversation);
    }

    displayConversationName(conversation: Conversation): string {
        const language = LIST_LANGUAGE.includes(this.language()) ? this.language() : 'de';
        return `${conversation.modifiedDate?.setLocale(language)?.toRelative() ?? ''}`;
    }
}
