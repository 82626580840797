<div
    class="mobi-ui-flex mobi-ui-centered-vertical mobi-ui-font-bold mobi-ui-font-size-200 mobi-ui-margin-t-lg mobi-ui-margin-b-xxs"
>
    <mobi-ui-icon-major
        class="mobi-ui-ink-black mobi-ui-padding-l-none mobi-ui-margin-r-xxs"
        [isInked]="true"
        name="signpost"
    ></mobi-ui-icon-major>
    {{ 'oiv.navigation.title.furtherTopics' | translate }}
</div>
<ul class="mobi-ui-list-minor is-block is-large is-indented-sm">
    @if (appType() === 'B2C') {
        <li>
            <div
                mobiTealiumTrackLinkOffsite="contact"
                trackingLinkCategory="OffSiteLink - Navigation"
                trackingLinkTarget="{{ links().notFoundPageContact }}"
                (click)="navigateToPage(links().notFoundPageContact)"
                [title]="'oiv.navigation.title.contact' | translate"
            >
                <span>{{ 'oiv.navigation.title.contact' | translate }}</span>
                <mobi-ui-icon-major name="arrow_upper_right"></mobi-ui-icon-major>
            </div>
        </li>
        <li>
            <div
                mobiTealiumTrackLinkOffsite="goToCustomerPortal"
                trackingLinkCategory="OffSiteLink - Navigation"
                trackingLinkTarget="{{ links().notFoundPageCustomerPortal }}"
                (click)="navigateToPage(links().notFoundPageCustomerPortal)"
                [title]="'oiv.navigation.title.goToCustomerPortal' | translate"
            >
                <span>{{ 'oiv.navigation.title.goToCustomerPortal' | translate }}</span>
                <mobi-ui-icon-major name="arrow_upper_right"></mobi-ui-icon-major>
            </div>
        </li>
        <li>
            <div
                mobiTealiumTrackLinkOffsite="goToLegalAdviser"
                trackingLinkCategory="OffSiteLink - Navigation"
                trackingLinkTarget="{{ links().mobiliarLegalAdviser }}"
                (click)="navigateToPage(links().mobiliarLegalAdviser)"
                [title]="'oiv.navigation.title.goToLegalAdviser' | translate"
            >
                <span>{{ 'oiv.navigation.title.goToLegalAdviser' | translate }}</span>
                <mobi-ui-icon-major name="arrow_upper_right"></mobi-ui-icon-major>
            </div>
        </li>
    }
    <li>
        <div
            mobiTealiumTrackLinkOffsite="visitProductPage"
            trackingLinkCategory="OffSiteLink - Navigation"
            trackingLinkAction="click"
            trackingLinkTarget="{{ metadata().productArtUrl }}"
            (click)="navigateToPage(metadata().productArtUrl)"
            [title]="'oiv.navigation.title.visitProductPage' | translate"
        >
            <span>{{ 'oiv.navigation.title.visitProductPage' | translate }}</span>
            <mobi-ui-icon-major name="arrow_upper_right"></mobi-ui-icon-major>
        </div>
    </li>
    @if (!isApprovalMode()) {
        <li>
            <div (click)="downloadPdf()" [title]="'oiv.navigation.title.downloadPDF' | translate">
                <span>{{ 'oiv.navigation.title.downloadPDF' | translate }}</span>
                <mobi-ui-icon-major name="download"></mobi-ui-icon-major>
            </div>
        </li>
    }
</ul>
