import { patchState, signalStoreFeature, type, withComputed, withMethods } from '@ngrx/signals';

import {
    addEntity,
    entityConfig,
    removeAllEntities,
    setAllEntities,
    updateEntity,
    withEntities,
} from '@ngrx/signals/entities';

import { computed } from '@angular/core';

import { DateTime } from 'luxon';

import { Language } from '@mobi/rwc-utils-ng-jslib';

import { Conversation, Message } from '../model/message.model';

const conversationConfig = entityConfig({
    entity: type<Conversation>(),
    collection: '_conversation',
});

export function withConversations<_>() {
    return signalStoreFeature(
        {
            state: type<{ textReference: string; language: Language; isIndexAvailable: boolean }>(),
        },
        withEntities(conversationConfig),
        withComputed(store => ({
            conversations: computed(() => store._conversationEntities()),
            previousConversations: computed(() => {
                const arrCopy = [...store._conversationEntities().filter(conversation => conversation.combinedId)];
                return arrCopy.sort((a, b) => b.modifiedDate.diff(a.modifiedDate).milliseconds);
            }),
        })),
        withMethods(store => ({
            _addTmpConversation(conversation: Conversation) {
                patchState(store, addEntity(conversation, conversationConfig));
            },
            updateConversation(conversation: Conversation) {
                conversation.modifiedDate = DateTime.now();
                patchState(
                    store,
                    updateEntity(
                        {
                            id: conversation.id,
                            changes: conversation,
                        },
                        conversationConfig,
                    ),
                );
            },
            updateMessages(conversationId: string, messages: Message[]) {
                patchState(
                    store,
                    updateEntity(
                        {
                            id: conversationId,
                            changes: {
                                modifiedDate: DateTime.now(),
                                messages: messages,
                            },
                        },
                        conversationConfig,
                    ),
                );
            },
            _addConversations(conversations: Conversation[]) {
                patchState(store, setAllEntities(conversations, conversationConfig));
            },
            removeAllConversations() {
                patchState(store, removeAllEntities(conversationConfig));
            },
        })),
    );
}
