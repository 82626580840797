import { deepComputed, getState, patchState, signalStore, withComputed, withHooks, withMethods } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { effect, inject } from '@angular/core';

import { concatMap, of, pipe, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { v4 as uuidv4 } from 'uuid';
import { map } from 'rxjs/operators';

import { DateTime } from 'luxon';

import { HttpErrorResponse } from '@angular/common/http';

import { withDevtools } from '@angular-architects/ngrx-toolkit';

import { MessageRequestDTO, QuestionAndAnswerService } from '../service/question-and-answer.service';
import { Conversation, Feedback, Message } from '../model/message.model';
import { OivViewerService } from '../../../../oiv-viewer/core/data/service/oiv-viewer.service';

import { AssetInfo, AssetPayload } from '../../../../share/model/atomic-model';

import { withFeedbackResponseStatus } from './with-feedback-response';

import {
    isIndexAvailable,
    setAssetType,
    setContentVersion,
    setLanguage,
    setTextReference,
    withAiState,
} from './with-ai-state';
import { withConversations } from './with-conversations';

export const AiStore = signalStore(
    withDevtools('AiStore'),
    withAiState(),
    withConversations(),
    withFeedbackResponseStatus(),
    withComputed(store => ({
        activeConversation: deepComputed(() => {
            const conversation = store
                .conversations()
                .find((conversation: Conversation) => conversation.id === store.currentConversationId());
            return {
                conversation:
                    conversation ??
                    ({
                        id: uuidv4(),
                    } as Conversation),
                isDisplaySuggestion: conversation ? conversation.messages.length == 0 : false,
                isFollowUpQuestion: conversation ? conversation.messages.length > 1 : false,
                isGenerating:
                    conversation?.messages.some((message: Message) => message.status === 'generating') ?? false,
            };
        }),
    })),

    withMethods(store => {
        const questionAndAnswerService = inject(QuestionAndAnswerService);
        const oivViewerService = inject(OivViewerService);
        const cancelSend$ = new Subject<void>();

        const initialize = (assetInfo: AssetInfo) => {
            cleanUp();
            patchState(store, setTextReference(assetInfo.textReference));
            patchState(store, setLanguage(assetInfo.language));
            patchState(store, setAssetType(assetInfo.assetType));
            patchState(store, setContentVersion(assetInfo.contentVersion));
            checkIndexAvailability();
        };

        const checkIndexAvailability = rxMethod<void>(
            pipe(
                tap(() => {
                    patchState(store, { isPending: true });
                    patchState(store, isIndexAvailable(false));
                }),
                switchMap(() => {
                    return oivViewerService
                        .checkIndexAvailability(
                            store.textReference(),
                            store.language(),
                            store.assetType(),
                            store.contentVersion(),
                        )
                        .pipe(
                            tapResponse({
                                next: isAvailable => {
                                    patchState(store, isIndexAvailable(isAvailable));
                                },
                                error: (err: HttpErrorResponse) => {
                                    return;
                                },
                                complete: () => {
                                    patchState(store, { isPending: false });
                                },
                            }),
                        );
                }),
            ),
        );

        const sendMessage = rxMethod<{
            requestPayload: MessageRequestDTO;
            currentConversation: Conversation;
            needToCreateConversation: boolean;
        }>(
            pipe(
                switchMap(({ requestPayload, currentConversation, needToCreateConversation }) => {
                    if (needToCreateConversation) {
                        const payload = {
                            textReference: store.textReference(),
                            language: store.language(),
                            assetType: store.assetType(),
                        } as AssetPayload;
                        return oivViewerService.saveConversation(payload).pipe(
                            tapResponse({
                                next: combinedId => {
                                    currentConversation.combinedId = combinedId;
                                    currentConversation.createdDate = DateTime.now();
                                    store.updateConversation(currentConversation);
                                },
                                error: (err: HttpErrorResponse) => {
                                    return;
                                },
                            }),
                            map(() => ({ requestPayload, currentConversation })),
                        );
                    } else {
                        return of({ requestPayload, currentConversation });
                    }
                }),
                map(({ requestPayload, currentConversation }) => {
                    const uuid = uuidv4();
                    const botMessage: Message = {
                        id: uuid,
                        content: '',
                        creationTimestamp: DateTime.now(),
                        role: 'assistant',
                        status: 'generating',
                    };
                    currentConversation.messages.push(botMessage);
                    store.updateConversation(currentConversation);
                    return { uuid, requestPayload, currentConversation };
                }),
                concatMap(({ uuid, requestPayload, currentConversation }) => {
                    requestPayload.conversation_id = currentConversation.combinedId || '';
                    return questionAndAnswerService.send(requestPayload).pipe(
                        takeUntil(cancelSend$),
                        tapResponse({
                            next: answer => {
                                const botMessage: Message = currentConversation.messages.find(
                                    item => item.id === uuid,
                                )!;
                                botMessage.content = answer.content;
                                botMessage.references = answer.references;
                                botMessage.status = 'completed';
                                store.updateConversation(currentConversation);
                            },
                            error: (err: HttpErrorResponse) => {
                                stopGeneratingRequested();
                            },
                        }),
                    );
                }),
            ),
        );

        const cleanUp = () => {
            cancelSend$.next();
            // clear the current AI state
            store._clearAiState();
        };

        const addTmpConversation = () => {
            const currentDate = DateTime.now();
            const conversation: Conversation = {
                id: uuidv4(),
                combinedId: '',
                textReference: store.textReference(),
                language: store.language(),
                messages: [],
                chosenFilter: '',
                createdDate: currentDate,
                modifiedDate: currentDate,
            };
            patchState(store, { currentConversationId: conversation.id });
            store._addTmpConversation(conversation);
        };

        const addConversations = (conversations: Conversation[]) => {
            store._addConversations(conversations);
        };

        const sendFeedback = rxMethod<{
            messageId: string;
            feedback: Feedback;
        }>(
            pipe(
                tap(() => store.setFeedbackPending(true)),
                switchMap(({ messageId, feedback }) => {
                    feedback.language = store.language();
                    feedback.textReference = store.textReference();
                    feedback.assetType = store.assetType();
                    feedback.contentVersion = store.contentVersion();
                    if (feedback.category == '') {
                        delete feedback.category;
                    }
                    return oivViewerService.sendFeedback(feedback).pipe(
                        tapResponse({
                            next: response => {
                                store.setFeedbackFulfilled(response);
                            },
                            error: (err: HttpErrorResponse) => {
                                return;
                            },
                            complete: () => {
                                store.setFeedbackPending(false);
                            },
                        }),
                    );
                }),
            ),
        );

        const loadPrompts = rxMethod<void>(
            pipe(
                switchMap(() => {
                    const payload = {
                        textReference: store.textReference(),
                        language: store.language(),
                        assetType: store.assetType(),
                    } as AssetPayload;
                    return oivViewerService.getPrompts(payload).pipe(
                        tapResponse({
                            next: response => {
                                patchState(store, { prompts: response });
                            },
                            error: (err: HttpErrorResponse) => {
                                return;
                            },
                        }),
                    );
                }),
            ),
        );

        const stopGeneratingRequested = () => {
            cancelSend$.next();
            const conversation = store
                .conversations()
                .find((conversation: Conversation) => conversation.id === store.currentConversationId());
            if (conversation) {
                const messages = conversation.messages.filter((message: Message) => message.status !== 'generating');
                if (messages.length < conversation.messages.length) {
                    store.updateMessages(store.currentConversationId(), messages);
                }
            }
        };

        const setCurrentSelectedFilterItem = (filterItem: string) => {
            const conversation = store
                .conversations()
                .find((conversation: Conversation) => conversation.id === store.currentConversationId());
            if (conversation) {
                conversation.chosenFilter = filterItem;
            }
        };

        return {
            initialize,
            cleanUp,
            checkIndexAvailability,
            sendMessage,
            addTmpConversation,
            addConversations,
            sendFeedback,
            loadPrompts,
            stopGeneratingRequested,
            setCurrentSelectedFilterItem,
            setActiveConversationId(conversationId: string) {
                patchState(store, { currentConversationId: conversationId });
            },
        };
    }),
    withHooks({
        onInit(store) {
            effect(() => {
                const state = getState(store);
                const key = `${state.textReference}-${state.language}-${state.assetType}-conversations`;
                // Add the conversation list to the local storage
                if (store.previousConversations() && store.previousConversations().length > 0) {
                    localStorage.setItem(key, JSON.stringify(store.previousConversations()));
                }
            });
        },
    }),
);
