import { Component, effect, HostListener, inject, input, output, signal, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTime } from 'luxon';

import {
    MobiUiDropdownComponent,
    MobiUiDropdownOptionComponent,
    MobiUiIconComponent,
    MobiUiIconsRegistry,
    MobiUiMessageItemComponent,
    MobiUiMessageLevel,
    MobiUiMessagesComponent,
} from '@mobi/rwc-ui-components-ng-jslib';

import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

import { Content } from '@mobi/oiv-viewer-utils-ng-jslib';

import { OIV_TYPE } from '@mobi/oiv-viewer-xml-parser-ng-jslib';

import { mobiIconInfo, mobiIconLeft, mobiIconPlaceholder } from '@mobi/rwc-utils-icons-jslib';

import { AssistantMessageComponent } from '../assistant-message/assistant-message.component';
import { PromptComponent } from '../prompt/prompt.component';
import { SeparatorComponent } from '../separator/separator.component';

import { UserMessageComponent } from '../user-message/user-message.component';
import { Conversation, Feedback, Message } from '../../data/model/message.model';
import { PromptDTO } from '../../../../oiv-viewer/core/data/service/oiv-viewer.service';

import { SearchResultItem } from '../../../index-search/ui/search-result-item/search-result-item.component';
import { Helper } from '../../../../share/helper';

import { ErrorMessageComponent } from '../error-message/error-message.component';

@Component({
    selector: 'oiv-viewer-conversation-detail',
    standalone: true,
    imports: [
        CommonModule,
        AssistantMessageComponent,
        MobiUiMessageItemComponent,
        MobiUiMessagesComponent,
        PromptComponent,
        SeparatorComponent,
        TranslatePipe,
        UserMessageComponent,
        MobiUiIconComponent,
        ErrorMessageComponent,
        MobiUiDropdownComponent,
        MobiUiDropdownComponent,
        MobiUiDropdownOptionComponent,
    ],
    templateUrl: './conversation-detail.component.html',
    styleUrl: './conversation-detail.component.scss',
})
export class ConversationDetailComponent {
    #helper = inject(Helper);

    dropdownComponent = viewChild.required(MobiUiDropdownComponent);

    conversation = input.required<Conversation>();
    chapterTitles = input<string[]>([]);
    prompts = input<PromptDTO[]>([]);
    rootChapter = input<Content>({
        eId: 'avb',
        type: OIV_TYPE.CHAPTER_TITLE,
        content: [],
    });
    feedbackCategories = input<string[]>([]);
    isMissingFilter = input<boolean>(false);
    displayPrompts = input<boolean>(true);
    baseUrl = input<string>('');

    backToOverview = output<void>();
    selectedPrompt = output<string>();
    sendFeedback = output<{
        messageId: string;
        feedbackData: Feedback;
    }>();
    filterOpen = output<boolean>();
    filterChange = output<string>();

    messages = signal<Message[]>([]);
    isFeedbackSent = signal(false);

    protected readonly MESSAGE_CLOSE_TIMEOUT = 3000;

    constructor() {
        inject(MobiUiIconsRegistry).registerIcons([mobiIconLeft, mobiIconInfo, mobiIconPlaceholder]);
        effect(
            () => {
                if (this.conversation()) {
                    this.messages.update(() => {
                        return this.conversation().messages;
                    });
                }
            },
            { allowSignalWrites: true },
        );
    }

    @HostListener('window:scroll', [])
    checkScrollPosition() {
        this.#addShadowOfBackButton();
    }

    renderMessageReference(message: Message): Message {
        return {
            ...message,
            content: this.#renderReferenceInMessageContent(message.content ? message.content : ''),
        };
    }

    #renderReferenceInMessageContent(message: string): string {
        const regex = /\[eID: ([^\]]+)]/gi; // this regex to detect the inline reference [eId] in the message content
        return message.replace(regex, (match, p1) => {
            const eIds = p1.split(/,\s*eID:\s*/i).map((eid: string) => eid.trim()); // support for detect multiple eIds in a bracket
            return eIds
                .map((eid: string) => {
                    const { numbering, url } = this.#handleSingleReference(eid);
                    return `[[${numbering}]](${url})`;
                })
                .join(', ');
        });
    }

    #handleSingleReference(eId: string): { url: string; numbering: string } {
        const content = this.#helper.searchContentByEid(eId, this.rootChapter());
        if (content) {
            const url = this.#helper.getUrlPath(content, this.rootChapter(), this.baseUrl());
            return {
                url,
                numbering: this.#helper.getNumberingOfContainer(content),
            };
        }
        return { url: '', numbering: '' };
    }

    handleReferences(message: Message): SearchResultItem[] {
        const contents = this.#getContentsByReferencesEid(message);
        return this.#buildReferencesByContents(contents);
    }

    #getContentsByReferencesEid(message: Message): Content[] {
        const references = message.references || [];
        // Wrap all the chapters into a root chapter support for searching
        return references
            .map(reference => this.#helper.searchContentByEid(reference.eID, this.rootChapter()))
            .filter((item): item is Content => item != null);
    }

    #buildReferencesByContents(contents: Content[]): SearchResultItem[] {
        if (!contents || contents.length === 0) {
            return [];
        }
        return contents.map(content => ({
            referenceId: content.eId,
            breadcrumb: this.#helper.buildBreadCrumbByHierarchy(content.hierarchy ? content.hierarchy : []),
            title: this.#helper.buildTitleForReference(content),
            description: '',
            urlPath: this.#helper.getUrlPath(content, this.rootChapter(), this.baseUrl()),
        }));
    }

    handleSendFeedback(feedback: any) {
        const messageId = feedback.messageId;
        // Need to use combinedId to identify the conversation (that is the id from server)
        const feedbackData = {
            conversationId: this.conversation().combinedId,
            type: feedback.type,
            category: feedback.category,
            detail: feedback.feedBackContent,
            // Assign the feedback into corresponding message
            conversationGeneralData: this.messages().map(message => {
                if (message.id === messageId) {
                    return {
                        ...message,
                        feedback: feedback.feedBackContent,
                        type: feedback.type,
                    };
                } else {
                    return message;
                }
            }),
        } as Feedback;
        this.sendFeedback.emit({
            messageId,
            feedbackData,
        });
        this.isFeedbackSent.set(true);
    }

    handleMessageClose(): void {
        this.isFeedbackSent.set(false);
    }

    selectPrompt(suggestion: string) {
        this.selectedPrompt.emit(suggestion);
    }

    handleBackToOverview() {
        this.backToOverview.emit();
    }

    getFeedbackData() {
        return {
            message: 'oiv.feedback.send.success',
            level: MobiUiMessageLevel.SUCCESS,
            statusCode: 200,
        };
    }

    handleValueChange(value: any) {
        if (value !== '') {
            this.filterChange.emit(value);
        }
    }

    dropdownClick() {
        this.filterOpen.emit(this.dropdownComponent().showOptions());
    }

    #addShadowOfBackButton() {
        const el = document.querySelector('.oiv-back-to-overview');
        if (el) {
            el.classList.add('button-shadow');
        }
    }

    displayDatetime(value: DateTime): string {
        return `${value?.toLocaleString(DateTime.TIME_24_SIMPLE) ?? ''}`;
    }
}
