import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { OIV_BASE_URL } from '../../../../util/constant/common';
import { Feedback } from '../../../../oiv-search/ai-search/data/model/message.model';
import { AssetPayload, AssetType, Avb, Index } from '../../../../share/model/atomic-model';

@Injectable({
    providedIn: 'root',
})
export class OivViewerService {
    #http = inject(HttpClient);
    #baseUrl = `${OIV_BASE_URL}`;

    getXml(urlName: string, language: string, isApprovalMode: boolean) {
        const params = new HttpParams({
            fromObject: {
                urlName,
                language,
            },
        });
        if (isApprovalMode) {
            return this.#http.get<Avb>(`${this.#baseUrl}/xml/retrieve/approval`, { params });
        }
        return this.#http.get<Avb>(`${this.#baseUrl}/xml/retrieve`, { params });
    }

    getIndices(textReference: string, language: string, assetType: string, contentVersion: string) {
        const params = new HttpParams({
            fromObject: {
                language,
                textReference,
                assetType,
                contentVersion,
            },
        });
        return this.#http.get<Index>(`${this.#baseUrl}/xml/indices/info`, { params });
    }

    reIndexing() {
        return this.#http.post(`${this.#baseUrl}/avb/general/synchronize/questionanswering`, null);
    }

    sendFeedback(feedback: Feedback) {
        return this.#http.post<Feedback>(`${this.#baseUrl}/questionanswering/feedback`, feedback);
    }

    saveConversation(payload: AssetPayload) {
        return this.#http.post(`${this.#baseUrl}/questionanswering/conversation`, payload, {
            responseType: 'text',
        });
    }

    getCategories() {
        return this.#http.get<string[]>(`${this.#baseUrl}/questionanswering/feedback/categories`);
    }

    checkIndexAvailability(textReference: string, language: string, assetType: string, contentVersion: string) {
        const params = new HttpParams()
            .set('textReference', textReference)
            .set('language', language)
            .set('assetType', assetType)
            .set('contentVersion', contentVersion);
        return this.#http.get<boolean>(`${this.#baseUrl}/questionanswering/index/availability`, { params });
    }

    getPrompts({ language, textReference, assetType = AssetType.ARTICLE_XML }: AssetPayload): Observable<PromptDTO[]> {
        const params = new HttpParams({
            fromObject: {
                language,
                textReference,
                assetType,
            },
        });
        const url = `${this.#baseUrl}/questionanswering/prompts`;
        return this.#http.get<PromptDTO[]>(url, { params });
    }

    getToggleFeatures(textReference: string) {
        return this.#http.get<string[]>(`${this.#baseUrl}/feature_toggles/textReference/${textReference}`);
    }
}

export interface PromptDTO {
    index: number;
    prompt: string;
}
