import { patchState, signalStoreFeature, withMethods, withState } from '@ngrx/signals';

import { Language } from '@mobi/rwc-utils-ng-jslib';

import { PromptDTO } from '../../../../oiv-viewer/core/data/service/oiv-viewer.service';
import { AssetInfo, AssetType } from '../../../../share/model/atomic-model';

export type AiState = {
    currentConversationId: string;
    isPending: boolean;
    isIndexAvailable: boolean;
    prompts: PromptDTO[];
} & AssetInfo;

export function withAiState() {
    return signalStoreFeature(
        withState<AiState>({
            textReference: '',
            language: 'de',
            assetType: AssetType.ARTICLE_XML,
            contentVersion: '',
            currentConversationId: '',
            isPending: true,
            isIndexAvailable: false,
            prompts: [],
        }),
        withMethods(store => ({
            _clearAiState() {
                patchState(store, {
                    currentConversationId: '',
                    isPending: true,
                    prompts: [],
                });
            },
        })),
    );
}

export function setTextReference(textReference: string) {
    return { textReference };
}

export function setAssetType(assetType: AssetType) {
    return { assetType };
}

export function setLanguage(language: Language) {
    return { language };
}

export function setContentVersion(contentVersion: string) {
    return { contentVersion };
}

export function isIndexAvailable(isIndexAvailable: boolean) {
    return { isIndexAvailable };
}
