<div
    (click)="createNewConversation()"
    class="mobi-ui-card-silent is-small is-gap is-row is-clickable"
    data-cy="add-new-conversation"
>
    <mobi-ui-icon-major name="message_conversation" />
    <span>{{ 'oiv.aiSearch.console.createNewConversation' | translate }} </span>
    <mobi-ui-icon-major class="oiv-margin-left-auto" name="item_new" />
</div>
<div class="mobi-ui-padding-t-lg">
    <ul class="mobi-ui-list-minor has-last-separator">
        @for (conversation of conversations(); track conversation.id) {
            <li
                class="mobi-ui-text-block-major mobi-ui-padding-y-md oiv-cursor"
                (click)="selectConversation(conversation)"
            >
                <mobi-ui-icon-major name="message_conversation" />
                <div class="mobi-ui-text-block-content-major is-small">
                    @if (conversation.chosenFilter === 'all-topics') {
                        <span class="mobi-ui-text-block-title-major"
                            >{{ 'oiv.aiSearch.filter.selectAllTopics' | translate }} -
                            {{ displayConversationName(conversation) }}</span
                        >
                    } @else {
                        <span class="mobi-ui-text-block-title-major"
                            >{{ conversation.chosenFilter }} - {{ displayConversationName(conversation) }}</span
                        >
                    }
                    <div class="mobi-ui-text-truncate">{{ conversation.messages[0].content }}</div>
                </div>
                <mobi-ui-icon-major name="right" />
            </li>
        }
    </ul>
</div>
