<div id="ai-search" class="ai-conversation">
    <div class="mobi-ui-padding-t-xs mobi-ui-padding-b-sm">
        <h4 class="mobi-ui-font-tisa mobi-ui-ink-default mobi-ui-padding-b-none mobi-ui-margin-b-xs">
            {{ 'oiv.aiSearch.title' | translate }}
        </h4>
    </div>

    <!--chat zone-->
    <div class="mobi-ui-width-full oiv-chat-zone">
        @if (!isPending()) {
            @if (isIndexAvailable()) {
                <oiv-viewer-conversation-overview
                    [hidden]="hideOverview()"
                    [conversations]="previousConversations()"
                    (newConversation)="createNewConversation()"
                    (selectedConversation)="selectConversation($event)"
                />
                <div [hidden]="!hideOverview()">
                    <oiv-viewer-conversation-detail
                        [conversation]="activeConversation.conversation()"
                        [chapterTitles]="chapterTitles()"
                        [rootChapter]="rootChapter()"
                        [prompts]="prompts()"
                        [feedbackCategories]="feedbackCategories()"
                        [isMissingFilter]="isMissingFilter()"
                        [displayPrompts]="activeConversation.isDisplaySuggestion()"
                        [baseUrl]="baseUrl()"
                        (selectedPrompt)="selectPrompt($event)"
                        (sendFeedback)="handleSendFeedback($event)"
                        (backToOverview)="handleBackToOverview()"
                        (filterChange)="handleFilterChange($event)"
                        (filterOpen)="handleFilterOpen($event)"
                    />
                    <oiv-console
                        [isGenerating]="activeConversation.isGenerating()"
                        [isIndexAvailable]="isIndexAvailable()"
                        [isFollowUpQuestion]="activeConversation.isFollowUpQuestion()"
                        [currentSelectedFilterItem]="activeConversation.conversation().chosenFilter"
                        (onMessageSent)="send($event)"
                        (onCancelGeneratingMessage)="stopGenerating()"
                        (onCreateNewConversation)="createNewConversation()"
                        [isDisplayCreateNewConversationButton]="conversationHasMessage()"
                    />
                </div>
            } @else {
                <oiv-viewer-error-message
                    [messageTitle]="'oiv.error.aiSearch.notAvailable.title' | translate"
                    [messageDescription]="'oiv.error.aiSearch.notAvailable.description' | translate"
                />
            }
        } @else {
            <mobi-ui-loading-indicator-major />
        }
    </div>
</div>
